import { Inject, Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { EventShopUser } from '../interfaces/eventShopUser';
import { apiUrlToken, oidcClientIdToken } from '../injection-tokens';
import { BehaviorSubject, concat, from, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { filter, first, map, mergeMap, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EUserRole } from '../enums/euser-role';
import { Log, User, UserManager } from 'oidc-client';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly redirectRouteKey = 'redirectRouteKey';
  private currentUser: EventShopUser = null;
  public signedInChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public userChanged$ = new ReplaySubject<EventShopUser>(1);

  private userManager: UserManager;
  private userSubject: BehaviorSubject<User | null> = new BehaviorSubject(null);
  private httpClient: HttpClient;

  constructor(private httpBackend: HttpBackend, @Inject(apiUrlToken) private apiUrl: string, @Inject(oidcClientIdToken) private oidcClientId: string, private router: Router) {
    this.httpClient = new HttpClient(httpBackend);

    // Log.logger = console;
    // Log.level = Log.DEBUG;

    this.isAuthenticated().subscribe(v => {
      if (v) {
        this.getEventShopUser();
      }
    })
  }


  public isAuthenticated(): Observable<boolean> {
    return this.getUser().pipe(map(u => !!u));
  }

  private getUser(): Observable<User | null> {
    return concat(
      this.userSubject.pipe(take(1), filter(u => !!u)),
      this.getUserFromStorage().pipe(filter(u => !!u), tap(u => this.userSubject.next(u))),
      this.userSubject.asObservable());
  }

  public getAccessToken(): Observable<string> {
    return from(this.ensureUserManagerInitialized())
      .pipe(mergeMap(() => from(this.userManager.getUser())),
        map(user => user && user.access_token));
  }

  public async signIn(state?: INavigationState) {
    await this.ensureUserManagerInitialized();
    await this.userManager.clearStaleState();

    try {
      await this.userManager.signinRedirect(this.createArguments(state));
    } catch (redirectError) {
      console.log('Redirect authentication error: ', redirectError);
    }
  }

  public async completeSignIn(url: string) {
    try {
      await this.ensureUserManagerInitialized();
      const user = await this.userManager.signinRedirectCallback(url);
      this.userSubject.next(user);
      if (user.state) {
        await this.router.navigate([(user.state as INavigationState).returnUrl])
      } else {
        await this.router.navigate(['/']);
      }
    } catch (error) {
      console.log('There was an error signing in: ', error);
    }
  }

  public async signOut(state?: any) {
    await this.ensureUserManagerInitialized();
    try {
      await this.userManager.signoutRedirect(this.createArguments(state));
    } catch (redirectSignOutError) {
      console.log('Redirect signout error: ', redirectSignOutError);
    }
  }

  public async completeSignOut(url: string) {
    await this.ensureUserManagerInitialized();
    try {
      await this.userManager.signoutRedirectCallback(url);
      this.userSubject.next(null);
    } catch (error) {
      console.log(`There was an error trying to log out '${error}'.`);
    }
  }

  private createArguments(state?: any): any {
    return {useReplaceToNavigate: true, data: state};
  }

  private async ensureUserManagerInitialized(): Promise<void> {
    if (this.userManager !== undefined) {
      return;
    }

    const response = await fetch(`${this.apiUrl}/api/OidcConfiguration/GetClientRequestParameters/${this.oidcClientId}`);
    if (!response.ok) {
      throw new Error(`Could not load settings for 'joinandmove'`);
    }

    const settings: any = await response.json();
    settings.automaticSilentRenew = true;
    settings.includeIdTokenInSilentRenew = true;
    settings.monitorSession = false;
    this.userManager = new UserManager(settings);
    this.userManager.clearStaleState().then(_ => {
    });
    this.userManager.events.addUserSignedOut(async () => {
      await this.userManager.removeUser();
      this.userSubject.next(null);
    });

    this.userManager.events.addAccessTokenExpiring(async () => {
      this.userManager.startSilentRenew();
    });
  }


  private getUserFromStorage(): Observable<User> {
    return from(this.ensureUserManagerInitialized())
      .pipe(
        mergeMap(() => this.userManager.getUser()));
  }

  public fetchUserInfo(): void {
    this.getUserObservable().pipe(first()).subscribe();
  }

  private getEventShopUser(): void {
    this.getUserObservable().pipe(first()).subscribe();
  }

  private getUserObservable(): Observable<EventShopUser> {
    return this.getCurrentUser().pipe(shareReplay(1), switchMap(u => of(u)), map(u => {
        this.currentUser = u;
        this.userChanged$.next(u);
        const isSignedIn = this.isSignedIn();
        this.signedInChanged$.next(isSignedIn);
        return u;
      }
    ));
  }

  private isSignedIn(): boolean {
    return this.currentUser != null;
  }

  public hasRole(userRole: EUserRole): boolean {
    return this.currentUser?.roles?.indexOf(userRole) > -1;
  }

  public getRedirectRoute(): string {
    return sessionStorage.getItem(this.redirectRouteKey);
  }

  private getCurrentUser(): Observable<EventShopUser> {
    return this.httpClient.get<EventShopUser>(`${this.apiUrl}/api/Users/CurrentUser`,
      {
        headers:
          {Authorization: this.authHeader()}
      });
  }

  public authHeader(): string {
    return `Bearer ${this.userSubject.value.access_token}`;
  }
}

interface INavigationState {
  returnUrl: string;
}
