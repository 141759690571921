import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  AuthService,
  CustomTranslationService,
  EPaymentMethod,
  ETimeOfDay,
  RegisterTeam,
  SportEvent,
  SportEventsService,
  Team,
  TeamsService,
  EIndoorSoccerEventTier
} from 'shared';
import { catchError, first, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Consts } from '../../consts';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  public registerTeamForm: FormGroup = new FormGroup({
    payed: new FormControl(false, [Validators.required]),
    comment: new FormControl(undefined),
    contactEmail: new FormControl(undefined, [Validators.required, Validators.email]),
    contactPhone: new FormControl(undefined, [Validators.required, Validators.pattern("[0-9]{8}")]),
    contactName: new FormControl(undefined, [Validators.required]),
    teamId: new FormControl(-1),
    teamName: new FormControl(undefined),
    playersCategory: new FormControl(-1, [Validators.required, Validators.min(1)]),
    paymentMethod: new FormControl(-1, [Validators.required, Validators.min(1)]),
    newTeam: new FormControl(false)
  });

  public sportEvent$: Observable<SportEvent>
  public teams$: Observable<Team[]>;

  private eventId: number;

  constructor(public customTranslationService: CustomTranslationService,
              private sportEventsService: SportEventsService,
              private activatedRoute: ActivatedRoute,
              private modalService: ModalService,
              public authService: AuthService,
              private teamsService: TeamsService,
              private router: Router,
              public utilService: UtilService) {
    this.setupFormValues();
    const params = this.activatedRoute.snapshot.params;
    if (params.eventId) {
      this.sportEvent$ = this.sportEventsService.getSportEvent(params.eventId as number).pipe(
        map(sportEvent => {
          this.registerTeamForm.controls.playersCategory.setValue(sportEvent.playersCategory);
          this.eventId = sportEvent.id;
          return sportEvent;
        }),
        catchError(err => {
          this.modalService.showModal('modal.error', 'global.eventNotFound')
          return of<SportEvent>();
        }));
    }
    this.teams$ = this.teamsService.getTeamsForUser();
  }

  ngOnInit(): void {
  }

  private setupFormValues() {
    for (const timeOfDay of this.getTimOfDay()) {
      this.registerTeamForm.addControl(timeOfDay, new FormControl(false));
    }

    this.registerTeamForm.controls.payed.setValue(false);
  }

  public getTimOfDay(): string[] {
    return Object.keys(ETimeOfDay);
  }

  public login() {
    this.authService.signIn({returnUrl: this.router.routerState.snapshot.url});
  }

  public register() {
    let registerTeam = this.registerTeamForm.value as RegisterTeam;
    registerTeam.registering = true;
    registerTeam.timeOfDay = [];
    for (const timeOfDay of this.getTimOfDay()) {
      if (this.registerTeamForm.controls[timeOfDay].value) {
        registerTeam.timeOfDay.push(timeOfDay as ETimeOfDay);
      }
    }

    if (this.registerTeamForm.controls.newTeam.value) {
      registerTeam.teamId = null;
    } else {
      registerTeam.teamName = null;
    }

    this.sportEventsService.registerTeam(this.eventId, registerTeam).pipe(first())
      .subscribe(_ => {
        this.modalService.showModal('event.registeredTitle', 'event.registeredContent');
        this.router.navigate([Consts.events]);
      }, error => this.modalService.showModal('modal.error', 'event.registeredErrorContent'));
  }

  public showPayed(): boolean {
    return this.registerTeamForm.controls.paymentMethod.value === EPaymentMethod.Transfer;
  }

  public isNormalEventTier(sportEvent: SportEvent): boolean {
    return sportEvent.indoorSoccerEventTier == EIndoorSoccerEventTier.Normal
  }

  public customValidation(): boolean {
    if (this.registerTeamForm.controls.newTeam.value) {
      return this.registerTeamForm.controls.teamName.value;
    } else {
      return this.registerTeamForm.controls.teamId.value > -1;
    }
  }
}
