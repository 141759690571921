<div class="container-fluid" *ngIf="team$ | async as team">
  <div class="row">
    <div class="col-md-6">
      <div class="team-container">
        <div class="container-fluid mt-0  ">
          <div class="row">
            <div class="col-12">
              <div class="team-image">
                <img *ngIf="team.logo" src="{{team.logo}}" alt="">
                <img *ngIf="!team.logo" src="./assets/teams/team.png" alt="">
              </div>
              <div class="team-desc item-header">
                <h5>{{ team.name }}</h5>
                <div class="team-description">
                  <quill-view-html [content]="team.description"></quill-view-html>
                </div>
                <p>{{ 'team.points'| translate }} {{ team.points }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div>
        <h3>{{ 'team.players' | translate }}</h3>
      </div>
      <div class="col-md-12">
        <app-player-list-item *ngFor="let player of team.players" [player]="player"></app-player-list-item>
      </div>
    </div>
  </div>
</div>
