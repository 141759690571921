import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, BreakpointObserverService, EUserRole, MenuEntry } from 'shared';
import { Consts } from '../../consts';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isHomePage$ = new BehaviorSubject(true);
  public mainMenu = [
    {
      route: '/',
      translationKey: 'header.menuItems.home'
    } as MenuEntry,
    {
      route: Consts.newsRoute,
      translationKey: 'header.menuItems.news'
    } as MenuEntry,
    {
      route: Consts.events,
      translationKey: 'header.menuItems.events'
    } as MenuEntry,
    // {
    //   route: Consts.rankings,
    //   translationKey: 'header.menuItems.rankings'
    // } as MenuEntry,
    // {
    //   route: Consts.newsRoute,
    //   translationKey: 'header.menuItems.liveScore'
    // } as MenuEntry,
    {
      route: Consts.rankings,
      translationKey: 'header.menuItems.ranking'
    } as MenuEntry,
    {
      route: Consts.livescore,
      translationKey: 'header.menuItems.livescore'
    } as MenuEntry,
    {
      link: '/assets/Regler-for-DM-i-indefodbold-og-DM-kvalifikationen.pdf',
      translationKey: 'header.menuItems.rules',
    } as MenuEntry,
    {
      link: 'https://www.facebook.com/events/474068581819496',
      translationKey: 'header.menuItems.dm',
    } as MenuEntry,
    // {
    //   route: Consts.community,
    //   translationKey: 'header.menuItems.community'
    // } as MenuEntry,
    // {
    //   route: Consts.newsRoute,
    //   translationKey: 'header.menuItems.forum'
    // } as MenuEntry,
    {
      route: Consts.contact,
      translationKey: 'header.menuItems.contact'
    } as MenuEntry,
  ];

  public defaultUserMenu = [
    {
      route: Consts.userRoot,
      translationKey: 'user.userInfo'
    } as MenuEntry,
  ];

  public userMenu: MenuEntry[] = []
  private subscription = new Subscription();

  constructor(public authService: AuthService, public breakpointObserver: BreakpointObserverService, private router: Router) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.mainMenu.forEach(m => {
          if (m.route === '/') {
            m.selected = this.router.url === '/';
            if (m.selected) {
              this.isHomePage$.next(true);
            }
          } else {
            m.selected = this.router.url.startsWith(`/${m.route}`);
            if (m.selected) {
              this.isHomePage$.next(false);
            }
          }
        });
      }
    });

    this.subscription.add(this.authService.userChanged$.subscribe(_ =>{
      this.userMenu = this.getUserMenu();
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
  }

  private getUserMenu(): MenuEntry[] {
    let userMenuEntries = [];
    if (this.authService.hasRole(EUserRole.GlobalAdmin)) {
      userMenuEntries = [
        // {
        //   translationKey: 'header.adminMenu.main',
        //   route: Consts.adminRootRoute
        // } as MenuEntry,
        {
          translationKey: 'header.adminMenu.news',
          route: `${Consts.adminRootRoute}/${Consts.adminNewsRoute}`
        } as MenuEntry,
        {
          translationKey: 'header.adminMenu.createNews',
          route: `${Consts.adminRootRoute}/${Consts.adminCreateNewsRoute}`
        } as MenuEntry,
        {
          translationKey: 'header.adminMenu.newsCategories',
          route: `${Consts.adminRootRoute}/${Consts.adminNewsCategoriesRoute}`
        } as MenuEntry,
        {
          translationKey: 'header.adminMenu.createNewsCategories',
          route: `${Consts.adminRootRoute}/${Consts.adminCreateEditNewsCategoryRoute}`
        } as MenuEntry,
        {
          translationKey: 'header.adminMenu.addTeamPoints',
          route: `${Consts.adminRootRoute}/${Consts.adminAddTeamPointsRoute}`
        } as MenuEntry
      ];
    }

    const defaultMenuEntries = [
      {
        route: `${Consts.userRoot}/${Consts.createEditEvent}`,
        translationKey: 'user.menu.createEvent'
      } as MenuEntry,
      {
        route: `${Consts.userRoot}/${Consts.userEvents}`,
        translationKey: 'user.menu.myEvents'
      } as MenuEntry,
      {
        route: `${Consts.teamAdminRootRoute}/${Consts.teamAdminCreateEditTeamRoute}`,
        translationKey: 'user.menu.createTeam'
      } as MenuEntry,
      {
        route: `${Consts.userRoot}/${Consts.userTeams}`,
        translationKey: 'user.menu.myTeams'
      } as MenuEntry
    ];
    return [...userMenuEntries, ...defaultMenuEntries];
  }

  public async signIn() {
    await this.authService.signIn()
  }

  public logout() {
    this.authService.signOut();
  }
}
