import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'eventShop-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {
  }

  async ngOnInit() {
    try {
      await this.authService.completeSignIn(window.location.href);
    } catch (e) {
      this.router.navigate(['/']);
    }
  }
}
