<div class="player-container">
  <div class="container-fluid ps-0 pe-0">
    <div class="row">
      <div class="col-md-6">
        <div class="player-image">
          <img *ngIf="player.image" src="{{player.image}}" alt="">
          <img *ngIf="!player.image" src="./assets/teams/user.png" alt="">
        </div>
      </div>
      <div class="col-md-6">
        <div class="player-header">
          <h5>
            {{player.firstName}} {{player.lastName}}
          </h5>
          <p>{{player.shortBiography}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
