import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'eventShop-logout-callback',
  templateUrl: './logout-callback.component.html',
  styleUrls: ['./logout-callback.component.css']
})
export class LogoutCallbackComponent implements AfterViewInit {
  constructor(private authService: AuthService, private router: Router) {
  }

  async ngAfterViewInit() {
    try {
      await this.authService.completeSignOut(window.location.href);
      await this.router.navigate(['/']);
    } catch (e) {
      await this.router.navigate(['/']);
    }
  }

}
