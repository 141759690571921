import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Consts } from '../../consts';
import { AuthService, SignUpUser, UsersService } from 'shared';
import { ModalService } from '../../services/modal.service';
import { PasswordValidation, UsernameValidation, UtilService } from '../../services/util.service';

@Component({
  selector: 'app-user-sign-up',
  templateUrl: './user-sign-up.component.html',
  styleUrls: ['./user-sign-up.component.scss']
})
export class UserSignUpComponen implements OnInit {
  public userForm: FormGroup = new FormGroup({
    firstName: new FormControl(undefined, [Validators.required]),
    lastName: new FormControl(undefined, [Validators.required]),
    phoneNumber: new FormControl(undefined, [Validators.pattern("[0-9]{8}"), Validators.required]),
    email: new FormControl(undefined, [Validators.required, Validators.email]),
    username: new FormControl(undefined, [Validators.required]),
    password: new FormControl(undefined, [Validators.required]),
    repeatPassword: new FormControl(undefined, [Validators.required])
  });

  public passwordValidation: PasswordValidation;
  public usernameValidation: UsernameValidation;

  constructor(private userService: UsersService, private authService: AuthService, private router: Router, private modalService: ModalService, public utilService: UtilService) {
  }

  ngOnInit(): void {
    this.userForm.controls.username.markAsTouched();
  }

  public haveToCheckUsername() {
    this.userForm.controls.username.setErrors({});
  }

  public checkUsername() {
    if (!this.userForm.controls.username.value) {
      return;
    }
    this.userService.checkUsername(this.userForm.value as SignUpUser)
      .pipe(first())
      .subscribe(r => {
        this.modalService.showModal('modal.usernameAvailableTitle', 'modal.usernameAvailableDesc');
        this.userForm.controls.username.setErrors(null);
      }, _ => {
        this.modalService.showModal('modal.usernameExistsTitle', 'modal.usernameExistsDesc');
        this.userForm.controls.username.setErrors({});
        this.userForm.controls.username.markAsTouched();
      });
  }

  public signUp() {
    if (this.userForm.valid && this.userForm.controls.password.value == this.userForm.controls.repeatPassword.value && this.utilService.passwordValid(this.passwordValidation)
      && this.utilService.usernameValid(this.usernameValidation)) {
      this.userService.signUp(this.userForm.value as SignUpUser).pipe(first())
        .subscribe(_ => {
          this.authService.signIn();
        });
    } else {
      if (this.userForm.controls.password.value != this.userForm.controls.repeatPassword.value || !this.utilService.passwordValid(this.passwordValidation)) {
        this.userForm.controls.password.setErrors({});
        this.userForm.controls.repeatPassword.setErrors({});
      }

      if (!this.utilService.usernameValid(this.usernameValidation)) {
        this.userForm.controls.username.setErrors({});
      }

      this.userForm.markAllAsTouched();
    }
  }

  public validatePassword() {
    this.passwordValidation = this.utilService.validatePassword(this.userForm.controls.password.value);
    if (!this.utilService.passwordValid(this.passwordValidation)) {
      this.userForm.controls.password.setErrors({});
    } else {
      this.userForm.controls.password.setErrors(null);
    }
  }

  public validateRepeatedPassword() {
    if (this.userForm.controls.password.value != this.userForm.controls.repeatPassword.value) {
      this.userForm.controls.repeatPassword.setErrors({});
    } else {
      this.userForm.controls.repeatPassword.setErrors(null);
    }
  }

  public validateUsername() {
    this.usernameValidation = this.utilService.validateUsername(this.userForm.controls.username.value);
    if (!this.utilService.usernameValid(this.usernameValidation)) {
      this.userForm.controls.username.setErrors({});
    } else {
      this.userForm.controls.username.setErrors(null);
    }
  }

}
