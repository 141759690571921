import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import {
  AuthInterceptor,
  apiUrlToken,
  ContentProviderInterceptor,
  contentProviderToken,
  LoadingInterceptor,
  SharedModule,
  oidcClientIdToken
} from 'shared';
import { HomeComponent } from './components/home/home.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HeaderComponent } from './components/header/header.component';
import '@angular/common/locales/global/da-GL';
import { TeamAdminComponent } from './components/team-admin/team-admin.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminNewsComponent } from './components/admin-news/admin-news.component';
import { ModalComponent } from './components/modal/modal.component';
import { AdminCreateEditNewsComponent } from './components/admin-create-edit-news/admin-create-edit-news.component';
import { ToastComponent } from './components/toast/toast.component';
import { AdminNewsCategoriesComponent } from './components/admin-news-categories/admin-news-categories.component';
import { AdminCreateEditNewsCategoryComponent } from './components/admin-create-edit-news-category/admin-create-edit-news-category.component';
import { TeamAdminTeamComponent } from './components/team-admin-team/team-admin-team.component';
import { TeamAdminCreateEditTeamComponent } from './components/team-admin-create-edit-team/team-admin-create-edit-team.component';
import { FileSelectionComponent } from './components/file-selection/file-selection.component';
import { UserComponent } from './components/user/user.component';
import { environment } from '../environments/environment';
import { FooterComponent } from './components/footer/footer.component';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { CategoriesSidebarComponent } from './components/categories-sidebar/categories-sidebar.component';
import { AdvertisementSidebarComponent } from './components/advertisement-sidebar/advertisement-sidebar.component';
import { NewsComponent } from './components/news/news.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { CategoryNewsComponent } from './components/category-news/category-news.component';
import { NewsListItemComponent } from './components/news-list-item/news-list-item.component';
import { EventsComponent } from './components/events/events.component';
import { RankingsComponent } from './components/rankings/rankings.component';
import { EventComponent } from './components/event/event.component';
import { CommunityComponent } from './components/community/community.component';
import { TeamComponent } from './components/team/team.component';
import { TeamListItemComponent } from './components/team-list-item/team-list-item.component';
import { PlayerListItemComponent } from './components/player-list-item/player-list-item.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { CreateEditEventComponent } from './components/create-edit-event/create-edit-event.component';
import { UserEventsComponent } from './components/user-events/user-events.component';
import { UserTeamsComponent } from './components/user-teams/user-teams.component';
import { AdminAddTeamPointsComponent } from './components/admin-add-team-points/admin-add-team-points.component';
import { SponsorSidebarComponent } from './components/sponsor-sidebar/sponsor-sidebar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { QuillModule } from 'ngx-quill'
import { UserSignUpComponen } from './components/user-sign-up/user-sign-up.component';
import { UserChangePasswordComponent } from './components/user-change-password/user-change-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetPasswordLinkComponent } from './components/reset-password-link/reset-password-link.component';
import { AddRemoveEventAdminsComponent } from './components/add-remove-event-admins/add-remove-event-admins.component';
import { ContactComponent } from './components/contact/contact.component';
import { UserEventComponent } from './components/user-event/user-event.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TeamAdminAddAdminComponent } from './components/team-admin-add-admin/team-admin-add-admin.component';
import { LivescoreComponent } from './components/livescore/livescore.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    TeamAdminComponent,
    AdminComponent,
    AdminNewsComponent,
    ModalComponent,
    AdminCreateEditNewsComponent,
    ToastComponent,
    AdminNewsCategoriesComponent,
    AdminCreateEditNewsCategoryComponent,
    TeamAdminTeamComponent,
    TeamAdminCreateEditTeamComponent,
    FileSelectionComponent,
    UserComponent,
    FooterComponent,
    SubscribeComponent,
    CategoriesSidebarComponent,
    AdvertisementSidebarComponent,
    NewsComponent,
    NotFoundComponent,
    NewsListComponent,
    CategoryNewsComponent,
    NewsListItemComponent,
    EventsComponent,
    RankingsComponent,
    EventComponent,
    CommunityComponent,
    TeamComponent,
    TeamListItemComponent,
    PlayerListItemComponent,
    EditUserComponent,
    CreateEditEventComponent,
    UserEventsComponent,
    UserTeamsComponent,
    AdminAddTeamPointsComponent,
    SponsorSidebarComponent,
    SidebarComponent,
    UserSignUpComponen,
    UserChangePasswordComponent,
    ResetPasswordLinkComponent,
    ResetPasswordComponent,
    AddRemoveEventAdminsComponent,
    ContactComponent,
    UserEventComponent,
    TeamAdminAddAdminComponent,
    LivescoreComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ClipboardModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    QuillModule.forRoot({
      theme: 'snow',
      format: 'html',
      modules: {
        toolbar: {
          container: [
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            // ['link'],
            ['clean']
          ]
        }
      }
    }),
    NgbModule
  ],
  providers: [
    {
      provide: apiUrlToken,
      useValue: environment.apiUrl
    },
    {
      provide: contentProviderToken,
      useValue: 'indebold'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentProviderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    {
      provide: oidcClientIdToken,
      useValue: environment.oidcClientId
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
