import { NgModule } from '@angular/core';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PaginationBaseComponent } from './components/pagination-base/pagination-base.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tab/tab.component';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CallbackComponent } from './components/callback/callback.component';
import { LogoutCallbackComponent } from './components/logout-callback/logout-callback.component';

@NgModule({
  declarations: [
    PaginationComponent,
    PaginationBaseComponent,
    TabComponent,
    TabsComponent,
    LoaderComponent,
    CallbackComponent,
    LogoutCallbackComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule
  ],
  exports: [
    PaginationComponent,
    PaginationBaseComponent,
    TabComponent,
    TabsComponent,
    LoaderComponent
  ]
})
export class SharedModule {
}
